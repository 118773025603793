import { Form, useField, useFormikContext } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement } from "react";

import Button from "../../../../../atoms/button/Button";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import * as styles from "./CardholderAddressForm.module.scss";

interface CardAddressFormProps {
  maxZipLength: number;
  hasSubmitError?: boolean;
}

const CardholderAddressForm = ({
  maxZipLength,
  hasSubmitError,
}: CardAddressFormProps): ReactElement => {
  const { t } = useTranslation();
  const [addressField] = useField<string>("cardHolderAddress");
  const [cityField] = useField<string>("cardholderCity");
  const [zipField] = useField<string>("cardholderZip");
  const { isValid, isSubmitting } = useFormikContext();

  const isSubmitDisabled = !isValid || isSubmitting;

  return (
    <Form className={styles.addressForm}>
      <TextFormControl
        {...addressField}
        label={t("pg_cardholder_address.address.field.label")}
        adoptionClassName={styles.address}
      />
      <TextFormControl
        {...zipField}
        label={t("pg_cardholder_address.zip.field.label")}
        adoptionClassName={styles.parentZip}
        maxLength={maxZipLength}
      />
      <TextFormControl
        {...cityField}
        label={t("pg_cardholder_address.city.field.label")}
        adoptionClassName={styles.city}
      />
      {hasSubmitError && <p className={styles.errorMessage}>{t("common.error_message")}</p>}
      <Button type="submit" disabled={isSubmitDisabled} isLoading={isSubmitting}>
        {t("common.cta.next")}
      </Button>
    </Form>
  );
};

export default CardholderAddressForm;
