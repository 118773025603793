import { FormikHelpers } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";

import PageStepAnimation from "../../../../../../atoms/page-step-animation/PageStepAnimation";
import { Image } from "../../../../../../core/utils/images";
import PageStepForm from "../../../../../../organisms/page-step-form/PageStepForm";
import {
  useResult,
  useUpdateResult,
} from "../../../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode, ZipCodeValidation } from "../../../../../../settings/countries";
import { Events, track } from "../../../../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../../../../utils/hooks/useTrackPageViewed";
import { getZipCodeLength } from "../../../../../../utils/locale-configuration-utils";
import CardholderAddressForm from "../../../organisms/cardholder-address-form/CardholderAddressForm";
import * as styles from "./CardholderAddress.module.scss";

interface CardholderAddressFormValues {
  cardHolderAddress: string;
  cardholderCity: string;
  cardholderZip: string;
}

const worldSVG: Image = require("../../../images/world.svg") as Image;

const worldSVGSrc: string = worldSVG.default;

const CardholderAddress = (): ReactElement => {
  const { t } = useTranslation();
  const result = useResult();
  const updateResult = useUpdateResult();
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

  const country = result?.country || CountryCode.ES;
  const ZipCodeValidation: ZipCodeValidation = getZipCodeLength(country);
  const nextUrl = `/results/${result?.uuid}/details/`;

  useTrackPageViewed(Events.CARDHOLDER_ADDRESS_VIEWED_BROWSER);

  const cardholderAddressFormData = {
    initialValues: {
      cardHolderAddress: result?.pet_parent_address || "",
      cardholderCity: result?.pet_parent_city || "",
      cardholderZip: result?.pet_parent_zip || "",
    },
    validationSchema: Yup.object({
      cardHolderAddress: Yup.string().trim().required(t("common.validation.required")),
      cardholderCity: Yup.string().trim().required(t("common.validation.required")),
      cardholderZip: Yup.string()
        .trim()
        .min(
          ZipCodeValidation.min,
          t("common.validation.zip_code.min_limit", { limit: ZipCodeValidation.min })
        )
        .required(t("common.validation.required")),
    }),
    handleSubmit: async (
      values: CardholderAddressFormValues,
      { setSubmitting }: FormikHelpers<CardholderAddressFormValues>
    ) => {
      if (!updateResult) {
        return;
      }

      setSubmitting(true);

      try {
        await updateResult({
          pet_parent_address: values.cardHolderAddress,
          pet_parent_city: values.cardholderCity,
          pet_parent_zip: values.cardholderZip,
        });

        track(Events.FORM_ANSWERED, {
          eventSender: "Cardholder Address",
          initialValues: {
            cardHolderAddress: result?.pet_parent_address,
            cardholderCity: result?.pet_parent_city,
            cardholderZip: result?.pet_parent_zip,
          },
          newValues: {
            cardHolderAddress: values.cardHolderAddress,
            cardholderCity: values.cardholderCity,
            cardholderZip: values.cardholderZip,
          },
          addressChanged: result?.pet_parent_address !== values.cardHolderAddress,
          cityChanged: result?.pet_parent_city !== values.cardholderCity,
          zipChanged: result?.pet_parent_zip !== values.cardholderZip,
        });

        void navigate(nextUrl);
      } catch (err) {
        handleUpdateResultErrors(err, "cardholder address");
        setHasSubmitError(true);
      } finally {
        setSubmitting(false);
      }
    },
    children: () => {
      return (
        <CardholderAddressForm
          maxZipLength={ZipCodeValidation.max}
          hasSubmitError={hasSubmitError}
        />
      );
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation adoptionClassName={styles.cardAddress}>
          <PageStepForm
            title={t("pg_cardholder_address.title")}
            image={worldSVGSrc}
            subtitle={t("pg_cardholder_address.subtitle")}
            formData={cardholderAddressFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default CardholderAddress;
